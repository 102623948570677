<template>
    <el-container>
        <el-container>
            <!-- 中间内容 -->
            <el-main>
                <h2 class="depTitle">绩效核算</h2>
                <div class="hosrow">
                    <div>
                        <el-select v-model="searcdepartmentId" placeholder="请选择科室" clearable @change="seardepId(searcdepartmentId)">
                            <el-option v-for="item in depoptions" :key="item.id" :label="item.name" :value="item.id">
                            </el-option>
                        </el-select>
                    </div>
                    <div style="margin-left:10px;">
                        <el-button type="primary" style="width:120px;" @click="depaddYear">核算</el-button>
                    </div>
                </div>
                <el-table :data="tableData" border center>
                    <el-table-column type="index" label="序号" width="55" align="center">
                    </el-table-column>
                    <el-table-column prop="name" label="核算名称" align="center">
                    </el-table-column>
                    <el-table-column prop="month" label="月份" align="center" width="110">
                        <template slot-scope="scope">
                            {{scope.row.year}}-{{scope.row.month}}
                        </template>
                    </el-table-column>
                    <el-table-column prop="totalAmount" label="月度绩效总金额" align="center">
                    </el-table-column>
                    <el-table-column prop="doctorProportion" label="医生分配比例" align="center" width="110">
                        <template slot-scope="scope">
                            {{Number(scope.row.doctorProportion*100).toFixed()}}%
                        </template>
                    </el-table-column>
                    <el-table-column prop="nurseProportion" label="护士分配比例" align="center" width="110">
                        <template slot-scope="scope">
                            {{Number(scope.row.nurseProportion*100).toFixed()}}%
                        </template>
                    </el-table-column>
                    <el-table-column prop="technicianProportion" label="技师分配比例" align="center" width="110">
                        <template slot-scope="scope">
                            {{Number(scope.row.technicianProportion*100).toFixed()}}%
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" align="center" width="250">
                        <template style="display: flex" slot-scope="scope">
                            <el-button size="mini" type="primary" @click="listJixiao(scope.row)"><i class="el-icon-notebook-2"></i></el-button>
                            <el-button size="mini" type="danger" @click="listdelete(scope.row)"><i class="el-icon-delete-solid"></i></el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background @current-change="handleCurrentChange" :current-page="pageNum" :page-size="pageSize" layout="prev, pager, next" :total="total">
                </el-pagination>
            </el-main>
        </el-container>
        <!-- 点击添加弹出框 -->
        <el-dialog title="科室绩效核算" :visible.sync="AdddialogVisible" width="855px" :before-close="AdddialogVisibleClose">

            <el-form :model="AddDateModel" ref="AddDateRef" :rules="AddDateRules" label-width="177px" :inline="true">
                <el-form-item label="核算名称：" prop="name">
                    <el-input v-model="AddDateModel.name" placeholder="请填写核算名称"></el-input>
                </el-form-item>
                <el-form-item label="科室：" prop="departmentId">
                    <el-select v-model="AddDateModel.departmentId" placeholder="请选择科室" clearable @change="changedepId(AddDateModel.departmentId)">
                        <el-option v-for="item in depoptions" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <!-- <el-form-item label="填报名称：" prop="fillname">
                    <el-select v-model="AddDateModel.fillname" placeholder="请选择填报名称" clearable @change="changefill(AddDateModel.fillname)">
                        <el-option v-for="item in optionaldate" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item> -->
                <el-form-item label="月份：" prop="year" v-show="yearShow">
                    <el-date-picker v-model="AddDateModel.year" type="month" value-format="yyyy-MM" placeholder="请选择月份" :picker-options="pickerOptions" @change="changeOptios">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="月度绩效总金额：" prop="totalamount">
                    <el-input v-model="AddDateModel.totalamount" placeholder="请填写月度绩效总金额"></el-input>
                </el-form-item>

                <el-form-item label="医生分配比例：" prop="docratio">
                    <el-input v-model="AddDateModel.docratio" placeholder="请填写医生分配比例"></el-input>
                </el-form-item>
                <el-form-item label="护士分配比例：" prop="nuratio">
                    <el-input v-model="AddDateModel.nuratio" placeholder="请填写护士分配比例"></el-input>
                </el-form-item>

                <el-form-item label="医生单项绩效分配系数：" v-if="erloShow" prop="doctorSingleProportion">
                    <el-input v-model="AddDateModel.doctorSingleProportion" placeholder="请填写医生单项绩效分配系数"></el-input>
                </el-form-item>
                <el-form-item label="护士单项绩效分配系数：" v-if="erloShow" prop="nurseSingleProportion">
                    <el-input v-model="AddDateModel.nurseSingleProportion" placeholder="请填写护士单项绩效分配系数"></el-input>
                </el-form-item>

                <el-form-item label="技师分配比例：" prop="techatio">
                    <el-input v-model="AddDateModel.techatio" placeholder="请填写技师分配比例"></el-input>
                </el-form-item>
                <el-form-item label="备注：" prop="remark">
                    <el-input type="textarea"  maxlength="250" show-word-limit v-model="AddDateModel.remark" placeholder="请填写备注"></el-input>
                </el-form-item>
                <el-form-item class="depar-dia">
                    <el-button class="addprimary" v-if="cesuanClick" type="primary" :loading="cesuanClickKing" @click="addYear">核算</el-button>
                    <el-button class="addprimary" v-else type="info" :loading="cesuanClickKing"></el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        <!-- 点击操作的第一个按钮弹出框 -->
        <el-dialog title="科室绩效薪酬" :visible.sync="HospitaldatadialogVisible" width="95%" :before-close="HospitaldatadialogVisibleClose" top="1vh">
            <div class="hosrowTable">
                <div class="hoswSmal">
                    <div class="hostextw">核算月份:{{accmonth}}月</div>
                    <div class="hostextw">绩效总额:{{totamo}}</div>
                    <div class="hostextw">医生占比:{{Number(docPro*100).toFixed()}}%</div>
                    <div class="hostextw">护士占比:{{Number(nurPro*100).toFixed()}}%</div>
                    <div class="hostextw">技师占比:{{Number(tenPro*100).toFixed()}}%</div>
                    <div class="hostextw">应发:{{reality}}</div>
                    <div class="hostextw">实发:{{performanceCalculationSum}}</div>
                    <div class="hostextw">差额:{{difference}}</div>
                </div>
                <div style="margin-left:auto;display:flex;">
                    <div>
                        <el-button type="primary" @click="recalculateClick" v-if="recalcuClick" :loading="recalcuClickTwo">重新测算</el-button>
                        <el-button type="info" v-else :loading="recalcuClickTwo"></el-button>
                    </div>
                    <div style="margin-left:10px;">
                        <el-button type="primary" @click="exportData" v-if="cesuanExpClick" :loading="cesuanExpClickTwo">导出数据</el-button>
                        <el-button type="info" v-else :loading="cesuanExpClickTwo"></el-button>
                    </div>
                </div>
            </div>

            <el-table :data="HospitaltableData" style="width: 95%; margin: 0 auto" border center>
                <el-table-column type="index" label="序号" width="55" align="center" :index="indexMethod">
                </el-table-column>
                <el-table-column prop="departmentUserName" label="姓名" align="center">
                </el-table-column>
                <el-table-column prop="basicScore" label="基本指标总分值" align="center" width="80">
                </el-table-column>
                <el-table-column prop="basicIndicators" label="基本指标绩效金额" align="center" width="90">
                </el-table-column>
                <el-table-column prop="workloadIndicators" label="工作量指标绩效金额" align="center" width="100">
                </el-table-column>
                <el-table-column prop="rewardIndicators" label="专项奖励绩效金额" align="center" width="80">
                </el-table-column>
                <el-table-column prop="subsidyIndicators" label="单项补助及奖惩绩效金额" align="center" width="110">
                </el-table-column>
                <el-table-column prop="checkAttendanceIndicators" label="个人考勤绩效金额" align="center" width="80">
                </el-table-column>
                <el-table-column prop="additionalIndicators" label="附加职责绩效金额" align="center" width="80">
                </el-table-column>
                <el-table-column prop="performanceCalculationSum" label="个人绩效总金额" align="center">
                </el-table-column>
                <el-table-column label="操作" align="center">
                    <template style="display: flex" slot-scope="scope">
                        <el-button size="mini" type="primary" @click="perdialo(scope.row)"><i class="el-icon-notebook-2"></i></el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination background @current-change="handleDetailCurrentChange" :current-page="pageDetailNum" :page-size="pageDetailSize" layout="prev, pager, next" :total="Detailtotal">
            </el-pagination>
        </el-dialog>
        <!-- 第二个表单的操作弹出框 -->
        <el-dialog :title="departmentUserName+'绩效薪酬明细'" :visible.sync="persondialogVisible" width="60%" :before-close="persondialogVisibleClose" top="1vh">
            <div class="perClas">
                <div class="btnExpCla">
                    <el-button type="primary">导出个人绩效薪酬明细</el-button>
                </div>
                <el-tabs v-model="activeName" @tab-click="handleClick">
                    <el-tab-pane label="基本指标" name="first" max-height="500">
                        <el-table :data="dataList.basicLibraryMoneyList" style="width: 100%" border>
                            <el-table-column prop="thirdLibraryName" label="指标名称" align="center">
                            </el-table-column>
                            <el-table-column prop="schemeThirdLibraryScore" label="指标系数" align="center">
                            </el-table-column>
                            <el-table-column prop="fillingScore" label="填报值" align="center">
                            </el-table-column>
                        </el-table>
                    </el-tab-pane>
                    <el-tab-pane label="工作量" name="second">
                        <el-table :data="dataList.workloadLibraryMoneyList" style="width: 100%" border max-height="500">
                            <el-table-column prop="thirdLibraryName" label="指标名称" align="center">
                            </el-table-column>
                            <el-table-column prop="schemeThirdLibraryScore" label="指标系数" align="center">
                            </el-table-column>
                            <el-table-column prop="fillingScore" label="填报值" align="center">
                            </el-table-column>
                        </el-table>
                    </el-tab-pane>
                    <el-tab-pane label="专项奖励指标" name="third">
                        <el-table :data="dataList.specialRewardsLibraryMoneyList" style="width: 100%" border max-height="500">
                            <el-table-column prop="thirdLibraryName" label="指标名称" align="center">
                            </el-table-column>
                            <el-table-column prop="schemeThirdLibraryScore" label="指标系数" align="center">
                            </el-table-column>
                            <el-table-column prop="fillingScore" label="填报值" align="center">
                            </el-table-column>
                        </el-table>
                    </el-tab-pane>
                    <el-tab-pane label="单项补助及奖惩指标" name="fourth">
                        <el-table :data="dataList.individualSubsidyLibraryMoneyList" style="width: 100%" border max-height="500">
                            <el-table-column prop="thirdLibraryName" label="指标名称" align="center">
                            </el-table-column>
                            <el-table-column prop="schemeThirdLibraryScore" label="指标系数" align="center">
                            </el-table-column>
                            <el-table-column prop="fillingScore" label="填报值" align="center">
                            </el-table-column>
                        </el-table>
                    </el-tab-pane>
                    <el-tab-pane label="个人考勤指标" name="five">
                        <el-table :data="dataList.personalAttendanceLibraryMoneyList" style="width: 100%" border max-height="500">
                            <el-table-column prop="thirdLibraryName" label="指标名称" align="center">
                            </el-table-column>
                            <el-table-column prop="schemeThirdLibraryScore" label="指标系数" align="center">
                            </el-table-column>
                            <el-table-column prop="fillingScore" label="填报值" align="center">
                            </el-table-column>
                        </el-table>
                    </el-tab-pane>
                    <el-tab-pane label="附加职责" name="six">
                        <el-tree :check-on-click-node="true" :default-checked-keys='checcheckKeys' :data="dataList.addResponsibilitiesLibraryMoneyList" show-checkbox node-key="thirdLibraryId" ref="nurtree" :props="defaultProps">
                        </el-tree>
                    </el-tab-pane>
                </el-tabs>
                <div class="btnCen" style="margin-top:10px;">
                    <el-button type="primary" @click="persondialogVisibleClose">关闭</el-button>
                </div>

            </div>

        </el-dialog>
    </el-container>
</template>

<script>
import _qs from "qs";
export default {
    data() {
        // 请输入数字
        var NumcheckAge = (rule, value, callback) => {
            if (!value && String(value) !== "0") {
                return callback(new Error("不能为空"));
            }
            setTimeout(() => {
                // console.log("number", Number.isInteger(value))
                if (!/^\d+$|^\d*\.\d+$/g.test(value)) {
                    callback(new Error("请输入数字！"));
                } else {
                    callback();
                }
            }, 100);
        };

        return {
            erloShow: false,
            pickerOptions: {
                disabledDate: (time) => {
                    let arr = this.picArr;
                    var lj = true;
                    arr.forEach((i) => {
                        lj =
                            lj &&
                            (time.getTime() <
                                new Date(
                                    i.split("-")[0],
                                    i.split("-")[1] - 1
                                ) ||
                                time.getTime() >
                                    new Date(
                                        i.split("-")[0],
                                        i.split("-")[1] - 1
                                    ));
                    });
                    return lj;
                },
            },
            reality: "", //应付
            defaultProps: {
                children: "children",
                label: "thirdLibraryName",
            },
            activeName: "first",
            persondialogVisible: false,
            yearShow: false,
            // 导出按钮置灰
            cesuanExpClick: true,
            cesuanExpClickTwo: false,
            recalcuClick: true,
            recalcuClickTwo: false,
            depoptions: [],
            filloptions: [],
            // 添加基期月份的添加按钮
            cesuanClick: true,
            cesuanClickKing: false,
            name: "",
            HospitaldatadialogVisible: false,
            AddDateModel: {
                year: "",
                departmentId: "",
                fillname: "",
                docratio: "",
                nuratio: "",
                totalamount: "",
                singleAmount: "",
                nurseSingleProportion: "",
                doctorSingleProportion: "",
                techatio: "",
                name: "",
                remark: "",
                fill: "",
            },
            departmentUserName: "",
            AddDateRules: {
                name: [
                    {
                        required: true,
                        message: "请填写核算名称",
                        trigger: "blur",
                    },
                ],
                techatio: [
                    { required: true, validator: NumcheckAge, trigger: "blur" },
                ],
                docratio: [
                    { required: true, validator: NumcheckAge, trigger: "blur" },
                ],
                nuratio: [
                    { required: true, validator: NumcheckAge, trigger: "blur" },
                ],
                totalamount: [
                    { required: true, validator: NumcheckAge, trigger: "blur" },
                ],
                singleAmount: [
                    { required: true, validator: NumcheckAge, trigger: "blur" },
                ],
                doctorSingleProportion: [
                    { required: true, validator: NumcheckAge, trigger: "blur" },
                ],
                nurseSingleProportion: [
                    { required: true, validator: NumcheckAge, trigger: "blur" },
                ],
                year: [
                    {
                        required: true,
                        message: "请选择月份",
                        trigger: "change",
                    },
                ],
                fillname: [
                    {
                        required: true,
                        message: "请选择填报名称",
                        trigger: "change",
                    },
                ],
                departmentId: [
                    {
                        required: true,
                        message: "请选择科室",
                        trigger: "change",
                    },
                ],
            },
            difference: "", //差额
            // 点击点击添加月份弹出框
            AdddialogVisible: false,
            type: "",
            // 医院基础数据基期分页
            // 当前页数
            pageNum: 1,
            // 每页显示条数
            pageSize: 10,
            Detailtotal: 1,
            pageDetailSize: 10,
            pageDetailNum: 1,
            // 表格数据
            tableData: [],
            // 医院表格
            HospitaltableData: [],
            // 医院id
            hospitalId: window.sessionStorage.getItem("hospitalId"),
            // 总页数
            total: 1,
            //这条数据id
            id: "",
            calculationId: "",
            searcdepartmentId: "",
            optionaldate: [],
            picArr: [], // 可选日期
            docPro: "",
            nurPro: "",
            tenPro: "",
            totamo: "",
            performanceCalculationSum: "", // 应发
            accmonth: "",
            newRow: {},
            fillInUserId: "",
            dataList: {},
            checcheckKeys: [],
            hospitalName: window.sessionStorage.getItem("hospitalName"),
        };
    },
    created() {
        this.gaoYaYangList();
        this.findDepartment();
    },
    methods: {
        async listdelete(row) {
            const confirmResult = await this.$confirm("确认删除？", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).catch((err) => err);

            if (confirmResult !== "confirm") {
                return this.$message.info("已经取消删除");
            }
            let data = _qs.stringify({
                id: row.id,
            });
            let { data: res } = await this.$axios.perDelete(data);
            // console.log(res);
            if (res.code == 401) {
                this.$router.push("/login");
            } else if (res.code == 200) {
                this.gaoYaYangList();
                this.$message({
                    message: res.msg,
                    type: "success",
                });
            } else {
                this.$message({
                    message: res.msg,
                    type: "error",
                });
            }
        },
        handleClick(tab, event) {
            // console.log(tab, event);
        },
        indexMethod(index) {
            return (this.pageDetailNum - 1) * this.pageDetailSize + index + 1;
        },
        // 导出按钮
        async exportData() {
            // 点击导出数据
            this.cesuanExpClick = false;
            this.cesuanExpClickTwo = true;
            let params = {
                calculationId: this.calculationId, //科室绩效计算id
                deleteFlag: "0", //状态（0正常 1已删除）
            };
            let res = await this.$axios.importExcel(params);
            // console.log(res);
            if (res.status == 200) {
                if (res.data.code == 401) {
                    this.$router.push("/login");
                } else if (res.code == 500) {
                    this.$message({
                        message: "导出失败",
                        type: "error",
                    });
                } else if (res.status == 200) {
                    let updateTime = this.addDate();
                    let blob = new Blob([res.data], {
                        type: "application/x-xls",
                    });
                    let link = document.createElement("a");
                    link.href = window.URL.createObjectURL(blob);
                    //配置下载的文件名
                    link.download = "科室绩效薪酬-" + this.accmonth + "月.xls";
                    link.click();
                    this.cesuanExpClick = true;
                    this.cesuanExpClickTwo = false;
                }
            }
        },
        //获取当前年月日
        addDate() {
            const nowDate = new Date();
            const date = {
                year: nowDate.getFullYear(),
                month: nowDate.getMonth() + 1,
                date: nowDate.getDate(),
            };
            const newmonth = date.month > 10 ? date.month : "0" + date.month;
            const day = date.date > 10 ? date.date : "0" + date.date;
            let updateTime = date.year + "-" + newmonth + "-" + day;
            return updateTime;
        },
        seardepId(searcdepartmentId) {
            // console.log(searcdepartmentId);
            this.searcdepartmentId = searcdepartmentId;
            this.gaoYaYangList();
        },
        // 点击核算
        depaddYear() {
            this.AdddialogVisible = true;
            this.findDepartment();
            this.erlonglu();
        },
        erlonglu() {
            if (
                window.sessionStorage
                    .getItem("hospitalName")
                    .search("二龙路") == "-1"
            ) {
                this.erloShow = false;
            } else {
                this.erloShow = true;
            }
        },
        recalculateClick() {
            this.recalcuClick = false;
            this.recalcuClickTwo = true;
            // console.log(this.newRow);
            this.gaoYaYangRecalculate();
        },
        async gaoYaYangRecalculate() {
            let data = _qs.stringify({
                id: this.newRow.id, //测算id
                name: this.newRow.name, //测算的名称
                departmentId: this.newRow.departmentId, //科室id
                fillInId: this.newRow.fillInId, //科室指标填报id
                totalAmount: this.newRow.totalAmount, //科室月度绩效总金额
                doctorProportion: this.newRow.doctorProportion, //医生分配比例
                nurseProportion: this.newRow.nurseProportion, //护士分配比例
                technicianProportion: this.newRow.technicianProportion, //技师分配比例
                remark: this.newRow.remark, //备注
            });
            let { data: res } = await this.$axios.gaoYaYangRecalculate(data);
            // console.log(res);
            this.recalcuClick = true;
            this.recalcuClickTwo = false;
            if (res.code == 401) {
                this.$router.push("/login");
            } else if (res.code == 500) {
                this.$message({
                    message: res.msg,
                    type: "error",
                });
            } else if (res.code == 200) {
                this.$message({
                    message: res.msg,
                    type: "success",
                });
                this.gaoYaYangDetailList();
                this.findPerformanceCalculationSum();
                this.findDifference();
                this.percfindByKey();
            }
        },
        async findDepartment() {
            let data = {
                hospitalId: window.sessionStorage.getItem("hospitalId"), //登录后返回的 医院id
                nameLike: "", //查询用的 科室名称
                orderByColumn: "createDate", //排序参数 updateDate 更新时间
                isAsc: "desc", //asc 升序 desc降序
            };
            let { data: res } = await this.$axios.findDepartment(data);
            // console.log(res);
            if (res.code == 401) {
                this.$router.push("/login");
            } else if (res.code == 200) {
                this.depoptions = res.data;
            }
        },
        // 选择月份的改变
        changeOptios(val) {
            // console.log(val);
            if (val) {
                this.AddDateModel.year = val;
                // console.log(this.optionaldate);
                this.optionaldate.map((item) => {
                    // console.log(item);
                    if (item.label == val) {
                        // console.log(item.id);
                        this.AddDateModel.fill = item.id;
                    }
                });
            } else {
                this.AddDateModel.year = "";
                this.AddDateModel.fill = "";
            }
        },

        changedepId(val) {
            if (val) {
                this.yearShow = true;
            } else {
                this.yearShow = false;
            }
            this.AddDateModel.departmentId = val;
            this.fillList();
            this.AddDateModel.fillname = "";
        },
        changefill(val) {
            // console.log(val);
            this.AddDateModel.fillname = val;
        },
        changeDate(val) {
            this.AddDateModel.year = val;
        },
        // 列表数据
        async fillList() {
            let arr = [];
            let data = {
                pageNum: "", //页数
                pageSize: "", //每页显示条数
                deleteFlag: "0", //状态（0正常 1已删除）
                departmentId: this.AddDateModel.departmentId, //科室id
                year: "", //年份
                month: "", //月份
            };
            let { data: res } = await this.$axios.fillList(data);
            // console.log(res);
            if (res.code == 401) {
                this.$router.push("/login");
            } else if (res.code == 200) {
                // console.log(res.rows);
                for (let i = 0; i < res.rows.length; i++) {
                    res.rows[i].label =
                        res.rows[i].year + "-" + res.rows[i].month;
                    arr.push(res.rows[i].year + "-" + res.rows[i].month);
                }
                this.picArr = arr;
                this.optionaldate = res.rows;
            } else {
                this.$message({
                    message: res.msg,
                    type: "error",
                });
            }
        },
        // 添加月份的时候取消正则
        AdddialogVisibleClose() {
            this.AdddialogVisible = false;
            this.AddDateModel = {
                year: "",
                departmentId: "",
                docratio: "",
                nuratio: "",
                totalamount: "",
                fill: "",
                departmentId: "",
                fillname: "",
            };
            this.yearShow = false;
            this.$refs.AddDateRef.resetFields();
        },
        // 医院基础数据基期分页
        handleCurrentChange(val) {
            this.pageNum = val;
            // console.log(`当前页: ${val}`);
            this.gaoYaYangList();
        },
        handleDetailCurrentChange(val) {
            this.pageDetailNum = val;
            this.gaoYaYangDetailList();
        },
        // 点击添加月份额添加
        addYear() {
            console.log(this.AddDateModel.fill);
            this.$refs.AddDateRef.validate(async (valid) => {
                if (valid) {
                    this.cesuanClick = false;
                    this.cesuanClickKing = true;
                    let data = _qs.stringify({
                        name: this.AddDateModel.name, //核算的名称
                        departmentId: this.AddDateModel.departmentId, //科室id
                        fillInId: this.AddDateModel.fill, //科室指标填报id
                        totalAmount: this.AddDateModel.totalamount, //科室月度绩效总金额
                        doctorProportion: this.AddDateModel.docratio, //医生分配比例
                        nurseProportion: this.AddDateModel.nuratio, //护士分配比例
                        technicianProportion: this.AddDateModel.techatio, //技师分配比例
                        singleAmount: this.AddDateModel.singleAmount, //科室单项绩效金额
                        nurseSingleProportion:
                            this.AddDateModel.nurseSingleProportion, // 护士单项绩效分配系数
                        doctorSingleProportion:
                            this.AddDateModel.doctorSingleProportion, // 医生单项绩效分配系数
                        remark: this.AddDateModel.remark, //备注
                    });

                    let res = await this.$axios.gaoYaYangCalculation(data);

                    // console.log(res);
                    this.cesuanClick = true;
                    this.cesuanClickKing = false;
                    if (res.data.code == 401) {
                        this.$router.push("/login");
                    } else if (res.data.code == 500) {
                        this.$message({
                            message: res.data.msg,
                            type: "error",
                        });
                    } else if (res.data.code == 200) {
                        this.$message({
                            message: res.data.msg,
                            type: "success",
                        });
                        this.AdddialogVisible = false;
                        this.gaoYaYangList();
                        this.AdddialogVisibleClose();
                    }
                }
            });
        },
        async gaoYaYangList() {
            let data = {
                departmentId: this.searcdepartmentId, //核算的名称
                pageNum: this.pageNum,
                pageSize: this.pageSize,
                deleteFlag: "0",
            };

            let { data: res } = await this.$axios.gaoYaYangList(data);
            // console.log(res);
            this.cesuanClick = true;
            this.cesuanClickKing = false;
            if (res.code == 401) {
                this.$router.push("/login");
            } else if (res.code == 500) {
                this.$message({
                    message: res.msg,
                    type: "error",
                });
            } else if (res.code == 200) {
                this.tableData = res.rows;
                this.total = res.total;
            }
        },
        // 点击操作删除
        listJixiao(row) {
            this.hospitalTitle = row.year;
            this.HospitaldatadialogVisible = true;
            this.calculationId = row.id;
            this.gaoYaYangDetailList();
            this.docPro = row.doctorProportion;
            this.nurPro = row.nurseProportion;
            this.tenPro = row.technicianProportion;
            this.totamo = row.totalAmount;
            this.accmonth = row.year + "年" + row.month;
            // this.difference = row.difference;
            this.findPerformanceCalculationSum();
            this.newRow = row;
            // console.log(row);
            //科室绩效测算后的差额
            this.findDifference();
            this.percfindByKey();
        },
        async percfindByKey() {
            let data = {
                id: this.calculationId,
            };
            let { data: res } = await this.$axios.percfindByKey(data);
            // console.log(res);
            this.reality = res.should;
        },
        async findDifference() {
            let data = _qs.stringify({
                id: this.calculationId,
            });
            let { data: res } = await this.$axios.findDifference(data);
            // console.log(res);
            if (res.code == 401) {
                this.$router.push("/login");
            } else if (res.code == 500) {
                this.$message({
                    message: res.msg,
                    type: "error",
                });
            } else if (res.code == 200) {
                // console.log(res.data.difference);
                this.difference = res.data.difference;
            }
        },
        perdialo(row) {
            this.fillInUserId = row.fillInUserId;
            this.findCalculationUserDetail();
            this.departmentUserName = row.departmentUserName;
        },
        async findCalculationUserDetail() {
            this.persondialogVisible = true;
            let data = {
                userFillInId: this.fillInUserId, //科室人员填报绩效数据id
            };
            let { data: res } = await this.$axios.findCalculationUserDetail(
                data
            );
            // console.log(res);
            if (res.code == 401) {
                this.$router.push("/login");
            } else if (res.code == 500) {
                this.$message({
                    message: res.msg,
                    type: "error",
                });
            } else if (res.code == 200) {
                // console.log(res);
                let checkKeys = [];
                for (
                    let i = 0;
                    i < res.data.addResponsibilitiesLibraryMoneyList.length;
                    i++
                ) {
                    res.data.addResponsibilitiesLibraryMoneyList[
                        i
                    ].disabled = true;
                    if (
                        res.data.addResponsibilitiesLibraryMoneyList[i]
                            .checkFlag == "1"
                    ) {
                        checkKeys.push(
                            res.data.addResponsibilitiesLibraryMoneyList[i]
                                .thirdLibraryId
                        );
                    }
                }
                this.dataList = res.data;
                this.checcheckKeys = checkKeys;
                // console.log(checkKeys);
            }
        },
        persondialogVisibleClose() {
            this.persondialogVisible = false;
            this.activeName = "first";
        },
        async findPerformanceCalculationSum() {
            let data = {
                calculationId: this.calculationId,
            };
            let { data: res } = await this.$axios.findPerformanceCalculationSum(
                data
            );
            // console.log(res);
            if (res.code == 401) {
                this.$router.push("/login");
            } else if (res.code == 500) {
                this.$message({
                    message: res.msg,
                    type: "error",
                });
            } else if (res.code == 200) {
                // console.log(res);
                this.performanceCalculationSum =
                    res.data.performanceCalculationSum;
            }
        },
        // 详情列表
        async gaoYaYangDetailList() {
            let data = {
                calculationId: this.calculationId,
                pageNum: this.pageDetailNum,
                pageSize: this.pageDetailSize,
                deleteFlag: "0",
            };
            let { data: res } = await this.$axios.gaoYaYangDetailList(data);
            // console.log(res);
            if (res.code == 401) {
                this.$router.push("/login");
            } else if (res.code == 500) {
                this.$message({
                    message: res.msg,
                    type: "error",
                });
            } else if (res.code == 200) {
                this.HospitaltableData = res.rows;
                this.Detailtotal = res.total;
            }
        },
        HospitaldatadialogVisibleClose() {
            this.HospitaldatadialogVisible = false;
            this.newRow = {};
            this.performanceCalculationSum = "";
            this.reality = "";
            this.difference = "";
            this.pageDetailNum = 1;
            this.gaoYaYangList();
        },
    },
};
</script>

<style lang="less" scoped>
/deep/ .updateTan .el-dialog__body {
    padding: 0;
}

.addprimary {
    margin-top: 10px;
    width: 150px;
}

.hosrowTable {
    width: 95%;
    margin: 0 auto;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    .hoswSmal {
        display: flex;
        align-content: center;
    }
    .hostextw {
        float: left;
        font-weight: bold;
        font-size: 16px;
        margin-right: 20px;
    }
}

/deep/ .tanchu .el-dialog {
    margin: 0 auto;
}

.hosrow {
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: right;
    margin-bottom: 10px;
}

.hosrow div:first-child {
    margin-left: 10px;
}

/deep/ .hosrow .el-input .el-input__inner {
    width: 200px;
}

.activeStatus {
    background: rgb(102, 204, 0);
}
.noStatus,
.activeStatus {
    width: 25px;
    height: 25px;
    color: white;
    line-height: 25px;
    text-align: center;
    margin: 0 auto;
}

.noStatus {
    background: #ccc;
}

.depTitle {
    display: flex;
    margin: 0 auto;
    margin-bottom: 20px;
}

.depar-dia {
    width: 100%;
    /deep/ .el-form-item__content {
        margin-left: 0px !important;
    }
}

.btnCen {
    .el-button {
        width: 150px;
        height: 40px;
    }
}

/deep/ .el-radio-button__inner {
    width: 100px;
}
.el-pagination {
    margin-top: 20px;
}

/deep/ .el-input .el-input__inner {
    width: 220px;
}

/deep/ .el-textarea__inner,
/deep/ .el-textarea {
    width: 220px !important;
}

.perClas {
    width: 95%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    .btnExpCla {
        margin-left: auto;
    }
}

.el-tree {
    display: flex;
    flex-wrap: wrap;
    /deep/ .el-tree-node {
        margin-bottom: 10px;
        width: 25%;
    }
}

/deep/ .el-tabs__item:focus.is-active.is-focus:not(:active) {
    box-shadow: none !important;
}

/deep/ .el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #409eff !important;
    border-color: #409eff !important;
}

/deep/ .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner::after {
    border-color: white !important;
}
</style>
